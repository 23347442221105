export default {
    'Name': '名称',
    'Permissions': '权限',
    'External': '外部',
    'Admin': '管理员',
    'AutoJoin': '自动加入',
    'AddGroup': '添加用户组',
    'EnterTheNameQuery': '输入名称查询',
    'View': '查看用户组',
    'Edit': '编辑用户组',
    'Create': '创建用户组',
    'GroupSettings': '用户组设置',
    'GroupName': '用户组名',
    'NameRequired': '请输入用户组名',
    'Description': '描述',
    'ExternalID': '外部ID',
    'ExternalIDDesc': '用于在 Azure AD 中配置相应用户组的新外部用户组 ID。',
    'Roles': '角色',
    'AdministerPlatform': '管理平台',
    'ManageResources': '管理资源',
    'ManageResourcesDesc': '启用后，用户可以管理对任何资源类型的权限（创建、编辑和删除），包括管道资源（集成、源和节点池）',
    'Options': '可选',
    'Automatically': '自动将新用户加入此用户组',
    'Users': '用户',
    'GroupPermissions': '用户组权限',
    'Repositories': '仓库',
    'Builds': '构建',
    'ReleaseBundles': '发布包',
    'PermissionsName': '权限名称',
    'Download': '下载',
    'DeployCache': '部署/缓存',
    'DeleteUpdate': '删除/更新',
    'PromoDistribution': '晋级/分发',
    'Total': '共',
    'Items': '条',
}
