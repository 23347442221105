export default {
    'AddPermission': '添加权限',
    'EnterTheStorageQuery': '选择存储空间查询',
    'EnterTheRepositoryQuery': '选择仓库查询',
    'EnterThePathQuery': '输入路径查询',
    'EnterTheNameQuery': '输入名称查询',
    'Name': '权限名称',
    'Confirm': '确认',
    'Cancel': '取消',
    'SingleName': '名称',
    'Users': '用户',
    'Groups': '用户组',
    'Operation': '操作',
    'View': '查看权限',
    'Edit': '编辑权限',
    'Create': '添加权限',
    'Resources': '资源',
    'ResourcesDesc': '选择权限适用的资源类型',
    'UsersDesc': '选择用户及其对所选资源的操作',
    'GroupsDesc': '选择用户组及其对所选资源的操作',
    'EnterTheNameCreate': '请输入唯一名称',
    'EnterTheNamePattern': '请输入英文大小写字母、_ 或 -',
    'AddRepositories': '添加仓库',
    'AddBuilds': '添加构建',
    'AddReleaseBundles': '添加发布包',
    'EditRepositories': '编辑仓库',
    'EditBuilds': '编辑构建',
    'EditReleaseBundles': '编辑发布包',
    'RepositoriesDesc': '设置仓库权限目标，以定义用户有权在仓库资源中查看的内容',
    'SelectRepositories': '选择仓库',
    'SetPatterns': '设置路径（可选）',
    'SelectRepositoriesDesc': '选择要应用权限的所有仓库',
    'RepositoriesPatternsDesc': '设置模式适用于所选仓库中的构件',
    'AvailableRepositories': '可用仓库',
    'SelectedRepository': '选定的仓库',
    'AllRepositories': '所有仓库',
    'PerRepository': '单个仓库',
    'OptionalTip': '您可以使用不同的粒度级别设置模式。',
    'IncludePatterns': '包含路径',
    'PatternsTip': '现有和未来仓库工件路径的简单逗号分隔通配符模式（无前导斜杠）。\n' +
        '支持 Ant 样式的路径表达式（*, **, ?）。\n' +
            '例如: org/apache/**',
    'ExcludePatterns': '排除规则',
    'NewPatterns': '输入新的路径',
    'ErrPatterns': '路径名不可重复',
    'Search': '搜索',
    'BundleTip': '设置发布包权限目标，以定义用户有权在发布包资源中查看的内容',
    'SelectBundle': '选择发布包仓库',
    'SelectBundleDesc': '选择要应用权限的所有发布包仓库',
    'BundlePatternsDesc': '设置规则适用于所选发布包仓库内的发布包',
    'AllBundles': '所有发布包仓库',
    'PerBundle': '每个发布包仓库',
    'BuildsDesc': '设置生成权限目标以定义哪些生成将包含在权限目标中',
    'ByBuildName': '按构建名称',
    'ByPattern': '按规则',
    'BuildOptionTip': '按名称选择构建或输入规则',
    'All': '全部',
    'Selected': '选中',
    'BuildName': '构建名称',
    'IncludeAllBuild': '包括所有构建',
    'Preview': '预览',
    'TotalBuilds': '构建总数',
    'BundlesPatternsTip': '使用 Ant 样式的通配符模式指定构建信息存储库中的现有和未来构建名称（即工件路径），这些名称将包含在此权限目标中。\n' +
        '支持 Ant 样式的路径表达式 (*, **, ?)。\n' +
        '例如, 一个 apache/** 规则将包括 "apache" 在权限中构建信息',
    'CheckTip': '包括所有版本会将所有现有和将来的版本添加到此权限。',
    'CheckConfirmTip': '您确定要继续吗？这将删除您手动输入的所有包含模式。',
    'SelectedUser': '选定的用户',
    'SelectedUsersRepositories': '选定的用户仓库',
    'SelectedUsersBuilds': '选定的用户构建',
    'SelectedUsersBundles': '选定用户发布包',
    'ResourceTip': '未选择资源类型',
    'NoPermissionsTip': '未选择任何权限。此用户将不会保存在权限目标中。',
    'Download': '下载',
    'DeployCache': '部署/缓存',
    'DeleteUpdate': '删除/更新',
    'PromoDistribution': '晋级/分发',
    'SelectAll': '全选',
    'SelectUsers': '选择用户',
    'SelectedGroups': '选择用户组',
    'SelectGroups': '选择用户组',
    'GroupNoPermissionsTip': '未选择任何权限。此用户组不会保存在权限目标中。',
    'SelectedGroupRepositories': '选定的用户组仓库',
    'SelectedGroupBuilds': '选定的用户组构建',
    'SelectedGroupBundles': '选定用户组发布包',
    'SelectedPermissions': '选择权限',
    'Repositories': '仓库',
    'StorageSpace': '存储空间',
    'SelectStorageSpace': '选择存储空间',
    'SelectStorageSpaceDesc': '选择要应用权限的存储空间',
    'AtLeastOneRepository': '请至少选择一个资源',
    'UserNoneSelectAuth': '用户至少选择一个权限',
    'UserSelected': '当前仓库已设置管理员，确认重新选择？',
    'GroupNoneSelectAuth': '用户组至少选择一个权限',
    'CheckAll': '全部选中',
    'UnselectAll': '取消全选',
    'DownloadDesc': '下载制品',
    'DeployCacheDesc': '部署制品并部署到远程仓库缓存',
    'DeleteUpdateDesc': '删除或覆盖制品',
    'PromoDistributionDesc': '制品晋级并分发',
    'Enabled': '开启',
    'Disabled': '关闭',

}
