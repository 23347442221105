export default {
    'Name': 'Name',
    'Permissions': 'Permissions',
    'External': 'External',
    'Admin': 'Admin',
    'AutoJoin': 'Auto Join',
    'AddGroup': 'Add Group',
    'EnterTheNameQuery': 'Search',
    'View': 'View Group',
    'Edit': 'Edit Group',
    'Create': 'Add Group',
    'GroupSettings': 'Group Settings',
    'GroupName': 'Group Name',
    'NameRequired': 'Please Input Group Name',
    'Description': 'Description',
    'ExternalID': 'External ID',
    'Roles': 'Roles',
    'ExternalIDDesc': 'New external group ID used to configure the corresponding group in Azure AD.',
    'AdministerPlatform': 'Administer Platform',
    'ManageResources': 'Manage Resources',
    'ManageResourcesDesc': 'When enabled, a user can manage permissions (Create, Edit and Delete) on any resource type, including Pipeline resources (Integration, source and node pools)',
    'Options': 'Options',
    'Automatically': 'Automatically Join New Users to this Group',
    'Users': 'Users',
    'GroupPermissions': 'Group Permissions',
    'Repositories': 'Repositories',
    'Builds': 'Builds',
    'ReleaseBundles': 'Release Bundles',
    'PermissionsName': 'Permissions Name',
    'Download': 'Download',
    'DeployCache': 'Deploy/Cache',
    'DeleteUpdate': 'Delete/Update',
    'PromoDistribution': 'Promotion/Distribution',
    'Total': 'Total',
    'Items': 'Items',
}
