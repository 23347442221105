export default {
    // Storages.vue
    'StorageList': '存储列表',
    's3Storage': 's3存储',
    'CopyStorageSpacePath': '复制存储空间路径',
    'ModifyStorageSpace': '修改存储空间',
    'createModal': '新 建',
    'CreateStorageSpace': '新建存储空间',
    'StorageSpaceName': '存储空间名称',
    'StorageType': '存储类型',
    'LocalStorage': '本地存储',
    'Note': '说明',
    'NFSStorage': '默认为本地存储即:NFS本地目录存储',
    'BucketNameDefinition': 'S3存储：默认以存储空间名称作为桶名,您也可以自定义桶名称',
    'unmodifiableNote': '注意：存储空间名称、存储类型、S3存储桶路径，一旦创建不可修改',
    'LocalPath': '本地路径',
    'S3Path': 'S3路径',
    'BucketName': '桶名称',
    'ParentDirectory': '父目录',
    'Custom': '自定义',
    'cancelCustom': '取消自定义',
    'Administrator': '管理员选择',
    'SelectAdministrator': '请选择管理员',
    'user': '用户成员选择',
    'selectUser': '请选择用户',
    'Cancel': '取消',
    'Confirm': '确定',
    'StorageSpaceOperation': '修改或删除存储空间',
    'SpaceNameRemain': '存储空间名称不允许修改',
    'BucketRemain': '存储类型、S3类型的桶均不允许修改',
    'ImportantNote': '说明（请谨慎操作！！！）',
    'HaveAccess': '你选择的管理员/成员列表将拥有该存储空间的使用权限',
    'RegularCleaning': '删除:只删除存储配置，每日0点会定时清理',
    'IfForcedDeletion': '若强制删除则无法恢复仓库列表',
    'Edit': '修改',
    'Delete': '删除',
    'create': '新建',
    'ForcedDeletion': '强制删除',
    'ConfirmDeletion1': '你确定要删除',
    'ConfirmDeletion2': '这个制品仓库么？请谨慎操作',
    'WarehouseName': '仓库名称',
    'LogicalDeletion': '删除:只逻辑删除，不删除安装包',
    'ProductWarehouse': '制品库',
    'Start': '开始',
    'yourFolib': '你的制品库',
    'Will': '将会在',
    'UnderStorageSpace': '存储空间下',
    'TypeSelection': '类型选择',
    'BasicInformation': '基础信息',
    'RemoteConfiguration': '远程配置',
    'CombinationConfiguration': '组合配置',
    'ClickNext': '不可修改，请点击下一步',
    'HowToChoose': '不知道怎么选择',
    'WarehouseTypeRemain': '修改模式下不可以更换仓库类型！',
    'IdentifyWarehouseType': '根据图标以及下发的类型名称进行识别，找到你要选择的仓库类型吧！',
    'NextVersion': '下一个版本更新即将呈现',
    'Next': '下一步',
    'FillInInformation': 'OK,接下来要填写基础信息',
    'DockerType': '你选择的是Docker仓库类型',
    'DifferentProcess': '选择不同的仓库策略要配置的流程不太一样',
    'KeywordPrompt': '不要出现仓库类型的关键字',
    'Strategy': '策略',
    'Local': '本地',
    'Agent': '代理',
    'Combination': '组合',
    'VersioningStrategy': '版本策略',
    'WarehousePath': '仓库路径',
    'DistributedRemain': '当前存储为分布式，不支持存储路径定义',
    'ItemLimit': '制品大小限制(MB)',
    'RepositorySizeLimit': '仓库存储限制(TB)',
    'StorageSizeLimit': '存储限制(TB)',
    'ServiceStatus': '服务状态',
    'OpenService': '开放服务',
    'ShutdownService': '关闭服务',
    'RecycleBin': '回收站',
    'UploadDeploy': '上传部署',
    'UploadOverlay': '上传覆盖',
    'DirectoryBrowsing': '目录浏览',
    'Allowed': '允许',
    'NotAllowed': '不允许',
    'Back': '回退',
    'Complete': '完成',
    'WarehouseConfig': '远程仓库配置',
    'RemoteLibraryAddress': '您选择的是远程仓库类型，还需要配置一下你的远程库地址，也可以开启本地代理访问远程地址',
    'RemoteAccessAddress': '远程访问地址',
    'AddressFormat': 'http://xxxx或者https://xxxx',
    'Test': '测试',
    'Username': '用户名',
    'AccessUser': '远程仓库访问用户名',
    'AccessPassword': '远程仓库访问密码',
    'TimedCheck': '定时检查时间(秒)',
    'DefaultTime': '默认60秒',
    'InspectionMechanism': '检查机制',
    'None': '无',
    'Strict': '严格',
    'LogRecording': '日志记录',
    'Advanced': '高级',
    'LocalAgent': '本地代理',
    'On': '开启',
    'Off': '不开启',
    'AutomaticLock': '自动封锁',
    'ChecksumCheck': '校验和检查',
    'RemoteIndexDownload': '远程索引下载',
    'Download': '下载',
    'NoDownload': '不下载',
    'ProxySetup': '代理设置',
    'UseProxySettings': '只有在当前制品库无法访问远程代理库的情况下，可以使用该功能',
    'Port': '端口号',
    'Protocol': '协议',
    'NoUserName': 'proxy的用户名，没有可以不填写',
    'Password': '密码',
    'NoPassword': 'proxy的用户密码，没有可以不填写',
    'CompositeWarehouse': '组合仓库配置',
    'WarehousePortfolio': '你选择的是组合仓库，可以将多个仓库从左至右进行拖动，进行组合.',
    'CustomizedStrategy': '给仓库配置定制策略',
    'TimingStrategy': '定时策略用来设定仓库垃圾清理，同步等相关策略',
    'HaveSet': '已设定',
    'ExpandSetting': '展开设定',
    'ExecuteOnce': '执行一次',
    'LoopExecution': '循环执行',
    'ImmediateExecution': '立即执行',
    'NoImmediateExecution': '不立即执行',
    'OtherParameters': '其他参数',
    'CompleteSetting': '完成策略设定',
    'EnterSpaceName': '请输入存储空间名称',
    'SpaceNameLengthLimit': '长度在 1 到 30 个字符',
    'SpaceNameLimit': '存储空间名称应为大小写字母，数字，特殊符号(-_.)',
    'SelectableRepository': '可选择制品仓库',
    'CompositeRepository': '已组合仓库',
    'LicenseError': 'License不正确',
    'CheckLicense': '请检查License是否存在',
    'LicenseExpired': 'License已过期',
    'AddWarehouseAfterRenewal': '请续期后再添加制品仓库',
    'CopySuccessful': '复制成功',
    'UnknownError': '未知错误',
    'StorageNameExists': '存储空间名称已存在',
    'FailedCreate': '创建失败',
    'OperationIncorrect': '操作不正确',
    'selectSpace': '你应该先从左侧选中一个存储空间,然后再新建仓库',
    'Success': '成功',
    'FillInCronExpression': '请填写cron表达式',
    'Failure': '失败',
    'FillInErrors': '填写错误',
    'EnterRepository': '请输入仓库名称',
    'RepositoryLengthLimit': '仓库名称长度在 1 到 30 个字符',
    'RepositoryLimit': '仓库名称应为大小写字母，数字，特殊符号(-_.)，不能以_.开头',
    'WarehouseModified': '仓库已修改完成',
    'WarehouseAdded': '仓库已新增完成',
    'RepositoryNameExists': '仓库名称已存在',
    'Deleted': '已删除',
    'ContentError': '要删除的内容填写错误',
    'ConnectedFine': '远程仓库连接正常',
    'ConnectedFailed': '远程仓库连接失败',
    'ResponseStatusCode': '响应状态码',
    'warehouseTips': ' （tips: 某些仓库不支持浏览也会返回404，但不影响构建使用，请检查该远程仓库是否为此类仓库）',
    'WarehouseSetup': '仓库设置',
    'PermissionSetting': '权限设置',
    'TimingPolicy': '定时策略',
    'FederatedRepository': '联邦仓库',
    'Scan': '扫描',
    // LibView.vue
    'Details': '详情',
    'Statistics': '统计',
    'MakingHistory': '制作历史',
    'ProductPath': '制品路径',
    'CreationTime': '创建时间',
    'LastTimeOfUse': '最近使用时间',
    'NumberOfDownloads': '下载次数',
    'ProductSize': '制品大小',
    'CVENumber': 'CVE编号',
    'VulnerabilityLevel': '漏洞等级',
    'CvssV2VulnerabilityLevel': 'CvssV2漏洞等级',
    'CvssV3VulnerabilityLevel': 'CvssV3漏洞等级',
    'CvssV2Score': 'CvssV2评分',
    'CvssV3Score': 'CvssV3评分',
    'ImportVersion': '引入版本',
    'VulnerabilityNumber': '漏洞编号',
    'IntroducingTime': '引入时间',
    'HighestVulnerabilityLevel': '最高漏洞等级',
    'RecommendedFixVersion': '建议修复版本',
    'Operation': '操作',
    'TheNumbers': '数字',
    'String': '字符串',
    'Text': '文本',
    'OperationSuccessful': '操作成功',
    // Scanner.vue
    'ScannedPacketsNum': '扫描包总数',
    'InsteadOfScanningPacketsNum': '无需扫描包总数',
    'SuccessScannedPacketsNum': '扫描成功的包数量',
    'FailedScansNUM': '扫描失败的包数量',
    'ScanDependenciesNum': '扫描依赖数量',
    'VulnerablePackagesNum': '具有漏洞的包数量',
    'VulnerabilitiesTotalNum': '漏洞总数',
    'sealedVulnerabilitiesNum': '封存漏洞数量',
    'Compare': '近7天漏洞分布视图',
    'packagesNum': '较上周包数量',
    'unknown': '不变',
    'unchanged': '未知',
    'dataCompared': '以下为本周与上周(14天)的数据进行比较的结果',
    'NewVulnerabilityPackage': '漏洞包新增',
    'ScanForNewDependencies': '扫描依赖新增',
    'NewVulnerabilityDependency': '漏洞依赖新增',
    'NewSealedVulnerabilitiesNum': '封存漏洞数新增',
    '30daysData': '近30天数据',
    '30daysDataChat': '本图为近30天数据,无新增数据的日期不展示',
    'VulnerabilitiesNum': '漏洞数量',
    'WarehouseScanningSituation': '仓库扫描情况',
    'PlatformVulnerability': '平台漏洞情况',
    'DependingOnQuantity': '依赖数量',
    'Warehouse': '仓库',
    'StorageSpace': '存储空间',
    'TotalNumberOfPackets': '包总数',
    'NumberOfQuestions': '问题包数',
    'SecurityScore': '安全评分',
    'EventRecord':'事件记录',
    'PromotionRecord':'晋级记录',
    'GitRepositoryAddr': 'git仓库地址',
    'GitProviderCredential': 'git私有仓库凭据',
    'GitUsername': 'git仓库用户名',
    'GitPassword': 'git仓库密码/token',
    'GitProvider': 'Git Provider',
    'AddGitCredential': '添加git凭据',
    'StorageOverview': '存储概览',
    'FilesSize':'文件大小',
    'FilesCount':'文件数量',
    'RepositoryCount': '仓库数量',
    'ArtifactsSize':'制品大小',
    'ArtifactsCount':'制品数量',
    'FoldersCount':'文件夹数量',
    'ItemsCount':'条目数量',
    'StorageDeviceType': '存储设备类型',
    'StorageQuotaSize':'存储空间配额',
    'DataUpdateTime':'数据更新时间',
    'LastTenUpdates': '近10次更新存储数据视图',
    'RepositoryList': '仓库列表',
    'ScanTime': '扫描时间',
}
