export default {
    // Settings.vue
    'GlobalConfiguration': ' global configuration',
    'TheWhiteList': 'White list',
    'SureAddedWhitelist': 'Are you sure you want to be added to the whitelist?',
    'Blacklist': 'Blacklist',
    'SureAddedBlacklisted': 'Are you sure you want to be blacklisted?' ,
    'BeSure': 'Confirm',
    'Cancel': 'Cancel',
    'Seriously': 'Seriously',
    'MediumRisk': 'Medium risk',
    'HighRisk': 'High risk',
    'LowRisk': 'Low risk',
    'selectUser': 'Please select User',
    'EnterEmail': 'Please enter Email',
    'VulnerabilityLevel': 'Vulnerability level',
    'SureDelete': 'Are you sure you want to delete it?',
    'RemovedWhitelistSuccess': ' Successfully removed from whitelist',
    'RemovedBlacklistSuccess': ' Successfully removed from the blacklist',
    'AddWhitelistSuccess': ' Added to whitelist successfully',
    'AddBlacklistSuccess': ' Added to blacklist successfully',
    'OperationSuccessful': 'Operation successful',
    'GlobalConfig': 'Global configuration',
    'BasicInfoConfig': 'Basic information configuration',
    'configuration': 'configuration',
    'NetworkAgentConfig': 'Network agent configuration',
    'AdvancedConfig': 'Advanced configuration',
    'BasicConfig': 'Basic configuration',
    'ApplicationName': 'Application name',
    'NodeTransmissionRateLimit': 'Node Transmission Rate Limit (KB/s)',
    'ProductTransferSliceSize': 'Product Transfer Slice Size (MB)',
    'PortNumber': 'Port',
    'Note': 'Note: ',
    'autoChangeConfigFile': 'Application name changes are automatically changed to the configuration file.',
    'TransferSliceSize': 'Slice size for artifact transfer, slice size for large artifact transfer between nodes',
    'NodeSpeedLimit': 'Node Transmission Rate limiting node transmission (KB/s). If this parameter is not set, the rate is not limited. If the transmission node rate limit is not set, the global rate limit is adopted.',
    'useBaseUrl': 'baseurl, you can use it if you use a reverse proxy on the public web and so on.',
    'communicationPort': 'The backend communication port of the main service.',
    'username': 'username',
    'Password': 'Password',
    'Port': 'Port',
    'ProtocolType': 'Protocol type',
    'SetSystemMail': 'This configuration is used to set the system mail.',
    'EmailNotification': 'Certain events in the application will send email notifications to relevant users.',
    'NetworkProxy': 'Network proxy',
    'ProxyUsername': 'Proxy username',
    'ProxyPassword': 'Proxy password',
    'ProxyAddress': 'Proxy address',
    'Type': 'Type',
    'NetworkCommunicationFailure': 'This configuration is used for certain situations where the network is unable to communicate.',
    'accessPublicNetwork': 'For example, you need to configure a proxy to access the public network.',
    'AcquisitionDependency': 'The dependencies of public repositories can be obtained through proxies.',
    'ForExample': 'ForExample:',
    'TurnOnAll': 'Turn on all',
    'TurnOn': 'On',
    'OnAllMeans': 'Enabling all means there are no more cross-origin restrictions.',
    'AllowingAnonymousAccess': 'Allowing anonymous access',
    'DisplayValidationFile': 'Display the validation file',
    'OpenAllowsAnonymousAccess': 'Open allows anonymous access, anonymous users can access public libraries and structures, closed anonymous users must login to normal use.',
    'OpenDisplayVerificationFile': 'Open display verification file, the warehouse details page will show the verification file, closed the warehouse details page will not show the verification file.',
    'SaveOperation': 'Save operation',
    'saveButton': 'This save button will save the above modifications uniformly.',
    'Cancel1': 'Cancel',
    'Save1': 'Save',
    'Save': 'Save',
    'SecurityPolicy': 'Security policy',
    'NotificationSet': 'Notification Settings',
    'NotifyVulnerabilityLevel': 'Notify the vulnerability level',
    'SelectVulnerabilityLevel': 'Please select the vulnerability level',
    'NotificationPolicy': 'Notification policy',
    'NotifyPlatformAdministrator': 'Notify the platform administrator',
    'NotifyStorageAdministrator': 'Notify the storage administrator',
    'DesignatedUser': 'Designated user',
    'SpecifyEmailAddress': 'Specify the email address',
    'BlockingSet': 'Blocking Settings',
    'SelectBlockingMode': 'Please select the blocking mode',
    'FullBlock': 'Full block',
    'autoFilterBlacklist': '(Tip: This blocking method will automatically filter the blacklist)',
    'FilteringWhitelist': 'Filtering the whitelist',
    'BlacklistBlocking': 'Blacklist blocking',
    'autoFilterWhitelist': '(Tip: This blocking method will automatically filter the white list)',
    'PackageNameBlocking': 'Package name blocking',
    'InterceptByPacketName': '(Tip: This blocking method will block according to the package name)',
    'Add': 'Add',
    'ConnectionConfig': 'Connection configuration',
    'UserMapping': 'User mapping',
    'RoleMapping': 'Role mapping',
    'LDAPConnection': 'This part of the configuration is used to establish a connection to LDAP',
    'BindingDN': 'Binding DN',
    'BindingPasswords': 'Binding passwords',
    'EnableLDAPServer': 'Whether to enable the LDAP server',
    'ShutDown': 'Off',
    'NextStep': 'Next',
    'UsersSearchForObjects': 'Users search for objects',
    'UserFilterCriteria': 'User filter criteria',
    'userDNList': 'The following list of user DN will be used in the validation lookup of the user',
    'Is64Encrypted': 'Is the user password Base64 encrypted?',
    'Yes': 'Yes',
    'No': 'No',
    'Back': 'Back',
    'RoleMatching': 'Role matching',
    'Match': 'Match',
    'FilteringCriteria': 'Filtering criteria',
    'RoleAttributes': 'Role attributes',
    'Role': 'Role',
    'EnterLDAPRole': 'Enter the LDAP role',
    'Done': 'Done',
    'NodeLicenseConfig': 'Node license configuration',
    'LicenseInfo': 'License information',
    'userChoose': 'Dear user, it is a great honor that you choose to use ',
    'InWhatFollows': 'In what follows, ',
    'provideYou': ' will provide you with unified package management.',
    'ITDigital': 'The e road of IT digital transformation is long. ',
    'IsWithYou': ' is with you!',
    'serviceHotline': ' If you feel that it conforms to the development strategy of your enterprise, you can choose to buy the official version. Our service hotline: 400-991-5355',
    'serialNumberExpired': ' Your serial number has expired, in order to provide better service for you, please renew as soon as possible. Our service hotline: 400-991-5355',
    'NotActivated': 'Not activated',
    'HasExpired': 'Has expired',
    'Activated': 'Activated',
    'MachineCode': 'Machine code',
    'VersionType': 'Version type',
    'None': 'None',
    'ExpirationDate': 'Expiration date',
    'SerialNumber': 'Serial number',
    'FunctionalLevel': 'Functional level',
    'WhetherItIsActiveOrNot': 'Whether it is active or not',
    'ActivateSequenceNum': 'Activate the sequence number',
    'OfficiallyActivated': 'Officially activated',
    'TryItOut': 'Try it out',
    'FOLIBSerialNumber': "You can copy the left machine code to get FOLIB's serial number and fill in the serial number below. Please ensure that the activation process can be connected to the Internet. If you need to open a network policy, please set 'license.folib.com' as a whitelist. If you want to activate it offline, please contact our technical service.",
    'machineCode': 'Please paste the serial number of this machine code',
    'MetadataConfig': 'Metadata configuration',
    'Display': 'Display',
    'NotToShow': 'Not to show',
    'NodeDistributeConfig': 'Node distribution configuration',
    'InternalNodes': 'Internal nodes',
    'Online': 'Online',
    'Offline': 'Offline',
    'Auto': 'Auto',
    'Manual': 'Manual',
    'NoSpeedLimit': 'No speed limit',
    'ExternalNodes': 'External nodes',
    'AddMetadata': 'Add metadata',
    'ModifyingMetadata': 'Modifying metadata',
    'MetadataKEY': 'Metadata KEY',
    'EnterMetadataKEY': 'Please enter the metadata KEY',
    'MetadataTypes': 'Metadata types',
    'SelectMetadataType': 'Please select the metadata type',
    'WhetherToDisplay': 'Whether to display',
    'AddWhitelist': 'Add whitelist',
    'AddBlacklist': 'Add blacklist',
    'EnterVulnerabilityNum': 'Please enter the vulnerability number',
    'AddDistributeConfig': 'Add distribution configuration',
    'ModifyDistributeConfig': 'Modify the distribution configuration',
    'ClusterNodeEnglish': 'English name of cluster node',
    'EnterClusterNodeEnglish': 'Please enter the English name of the cluster node',
    'ClusterChineseName': 'Cluster Chinese name',
    'EnterClusterChinese': 'Please enter the Chinese name of the cluster',
    'ClusterNodeAddress': 'Cluster node address',
    'EnterClusterNodeAddress': 'Please enter the cluster node address',
    'ClusterDescribe': 'Cluster description',
    'EnterDescribe': 'Please enter a description',
    'EnterTransmissionLimitOfNode': 'Please enter the node transmission rate (KB/s)',
    'distributeType': 'Distribution type',
    'SelectDistributeType': 'Please select the distribution type',
    'LocalCluster': 'Local cluster',
    'SyncData': 'Sync Data',
    'ClientUniqueIdentifier': 'Client unique identifier',
    'ClientID': 'Client ID',
    'EnterClientID': 'Please enter the client ID',
    'Edit': 'Edit',
    'ClientKey': 'Client key',
    'EnterClientKey': 'Please enter the client key',
    'ClientName': 'Client name',
    'CustomClientName': 'Custom client names to distinguish between multiple single sign-on configurations',
    'EnterClientName': 'Please enter the client name',
    'LoginAddress': 'Login address',
    'ThirdPartySystemProvideLogin': 'The login address of the single sign-on system, provided by the third-party login system',
    'EnterLoginPageAddress': 'Please enter the login page address',
    'LoginRedirectAddress': 'Login redirect address',
    'RedirectAddressAfterLogin': 'The redirect address after login',
    'EnterRedirectAddress': 'Please enter the redirect address after login',
    'LogoutAddress': 'Logout address',
    'ClearSession': 'The logout address of the single sign-on system, provided by the third party login system, used to clear the session of the server',
    'EnterYourLogoutAddress': 'Please enter your logout address',
    'LogOutRedirectURL': 'Log out the redirect URL',
    'RedirectURLAfterLogin': 'The redirected address after logout',
    'EnterRedirectURL': 'Please enter the redirect URL after logging out',
    'Address': 'Address',
    'GetInterfaceAddress': 'Get the interface address of AccessToken',
    'EnterAccessTokenAddress': 'Please enter the AccessToken address',
    'UserInfoAddress': 'User information address',
    'EnterUserInfoAddress': "The address of the interface to fetch the user's information",
    'EnterInformationAddress': 'Please enter the user information address',
    'UserNameField': 'User name field',
    'setUserNameField': 'When this field is set, it will be used as the username of the system',
    'EnterUserNameField': 'Please enter the user name field',
    'ConfigInstructions': 'Configuration instructions',
    'SSOConfigCustomDescribeInfo': 'The SSO config custom description information',
    'EnterConfigInstructions': 'Please enter the configuration instructions',
    'Node': 'Node',
    'describe': 'Description',
    'distributeMethod': 'Distribution method',
    'OnlineStatus': 'Online status',
    'AddMethod': 'Add method',
    'Operation': 'Operation',
    'enterNodeUrl': 'Please enter the node url',
    'RedirectAddress': 'Redirect address',
    'SavedSuccess': 'Saved successfully',
    'CopySuccess': 'Copy successfully',
    'SuccessfulActivation': 'Successful activation',
    'ActivationFailed': 'Activation failed',
    'UnableToActivate': 'Unable to activate',
    'NoSequenceNumberWasEntered': 'No sequence number was entered',
    'BlockingSetSavedSuccess': 'Blocking Settings saved successfully',
    'NotificationSetSavedSuccess': 'Notification Settings saved successfully',
    'ClusterDistributeConfigExists': 'Cluster distribution configuration already exists',
    'distributeConfigSuccessful': 'Distribution configuration successful',
    'MetadataKEYyExists': 'The metadata KEY already exists',
    'metadataConfigSuccess': 'The metadata is configured successfully',
    'DeleteDistributeConfigSuccess': 'Delete distribution configuration successfully',
    'DeletingMetadataWasSuccessful': 'Deleting metadata succeeded',
    'EditClient': 'Edit client',
    'Tips': 'Tips',
    'DeleteClientSuccess': 'Delete client successfully',
    'AddNewClient': 'Add a new client',
    'AddedClientSuccess': 'Added client successfully',
    'UpdateClientSuccess': 'Update client successfully',
    'ClusterEnNameLengthLimit': 'Length between 1 and 60 characters',
    'CheckKey': 'Length between 1 and 30 characters',
    'CheckDispatchType': 'Please select a distribution mode',
    'Number': 'Number',
    'String': 'String',
    'Text': 'Text',
    // Monitor.vue
    'BaseMonitoring': 'Base monitoring',
    'SystemLog': 'System log',
    'dataQuery': 'data query',
    'clusterInformation': 'cluster information',
    // QueryData.vue
    'QueryLog': 'Query log',
    'Query': 'Query',
    'SeeOfficialDocument': 'See the official documentation for the Gremlin query language',
    '8182Port': 'The default port for querying the built-in Gremlin database server is 8182',
    'TopologicalResult': 'Topological result',
    // BaseData.vue
    'handleCase': 'In the handle case',
    'maximumSystemHandle': 'The maximum system handle is ',
    'StorageSize': 'Storage size',
    'MaximumSystemSpace': 'Maximum system space',
    'availableJVM': 'Is the amount of memory available to the JVM',
    'AvailableMemory': 'Available memory',
    'NumberOfThreads': 'Number of threads',
    'currentProgramNum': 'The number of threads in the current program',
    'SystemLoad': 'System load',
    'CurrentSystemLoadEstimate': 'Current system load estimate',
    'TimeConsuming': 'Time-consuming',
    'ExecutionTime': 'Execution time',
    'SystemCPU': 'System CPU',
    'FolibProcessCPU': 'Folib process CPU',
    'MemoryUsage': 'Memory usage',
    'CPUUsage': 'CPU usage',
    'MaxAvailableMemory': 'Max available memory',
    'FolibCurrentlyUsesMemory': 'Folib currently uses memory',
    'InTheThreadCase': 'In the thread case',
    'NumberOfActiveThreads': 'Number of active threads',
    // SystemLog.vue
    'LogList': 'Log list',
    'OnlineLogView': 'Online log view',
    'synchronization': 'synchronization',
    // ClusterInfo.vue
    'DataRepair': 'Data repair',
    'NodeInformation': 'Node information',
    'DangerousOperation': 'Dangerous OPERATION!! Are you sure you want to remove this node?',
    'BasicInformation': 'Basic information',
    'Status': 'Status',
    'UsingPorts': 'Using ports',
    'TotalNumberOfDataTables': 'Total number of data tables',
    'DataOwnership': 'Data ownership',
    'EstimatedDataSize': 'Estimated data size',
    'RemovingNodes': 'Node ',
    'refreshThePageToCheck': ' is being removed. Please refresh the page later',
    'SuccessRemovedNode': 'Successfully removed node: ',
    'clusterDataIsRepaired': 'The cluster data is repaired',
    // AuditLog.vue
    'auditLog':'Audit Log',
    'auditModule':'auditModule',
    'auditEvent':'auditEvent',
    'eventTime':'eventTime',
    'startTime':'startTime',
    'endTime':'endTime',
    'detail':'detail',
    'result':'result',
    'operator':'operator',
    'createTime':'createTime',
    'target':'target',
    'auditConfig':'Audit Config',
    'artifactRepository':'Artifact Repository',
    'userManagement':'User Management',
    'systemSetting':'System Setting',
    'advanceSetting':'Advance Setting',
    // Advanced.vue
    'StorageSpace': 'storage space',
    'OwnedWarehouse': 'Owned warehouse',
    'BuildData': 'Build data',
    'BuildAnArtifactIndex': 'Build artifact index',
    'migrateArtifactData': 'This feature is used to migrate artifact data from JFrog Artifactory, Sonatype Nexus',
    'InMostRecentBuild': 'In the most recent build',
    'User': 'User',
    'Time': 'Time',
    'Parameters': 'Parameters',
    'Error': 'Error',
    'sureChangeState': 'Are you sure you want to change state?',
    'ManualClosing': 'Manual closing',
    'In': 'In',
    'selectTheStorageSpace': 'Please select the storage space',
    'selectYourRepository': 'Please select your repository',
    'ProductAbsolutePath': 'Relative path of Artifacts',
    'specifyDirectoryBuildData': 'You can specify the directory to build data, otherwise it is the root directory of the repository',
    'DirectoryAbsolutePath': 'The directory is the relative path under the repository',
    'enterAbsoluteProductPath': 'Please enter the relative path of the artifact',
    'SynchronizingMetadata': 'Synchronizing metadata',
    'sureBuildData': 'Are you sure you want to build your data? ',
    'MavenDataMigration': 'Maven data migration',
    'ArtifactDataMigration': 'Artifact data migration',
    'MavenIndxerSonatypeNexus': 'This feature is used to migrate artifact data from JFrog Artifactory, Sonatype Nexus using MavenIndxer mode',
    'BugUpdate': 'Vulnerability update',
    'UpdateVulnerabilityData': 'Update vulnerability data',
    'updateDataToLibrary': 'This function is used to update the vulnerability data to the local vulnerability library',
    'TheLastUpdate': 'Last update',
    'SureUpdateTheVulnerabilityData': 'Are you sure to update vulnerability data now?',
    'Update': 'Update',
    'BackupStrategy': 'Backup strategy',
    'setTheBackupPolicy': 'This function is used to set the backup policy to backup the artifact data of the specified storage space and warehouse',
    'CachingStrategy': 'Caching strategy',
    'setSSDCacheAccelerationStrategy': 'This function is used to set the SSD cache acceleration strategy of the artifact, and recommend the storage volume to use high-performance SSD disks.',
    'Information': ' Information',
    'viewTheSchemaInformation': 'This function is used to view the Schema information',
    'Notice': 'Notice',
    'checkIndexInformation': ' index is not valid, please check the index information!',
    'IndexingOperations': 'Indexing operations',
    'usedIndexReconstructionRegistration': 'This function is used for index reconstruction, registration',
    'IndexName': 'Index name',
    'enterTheIndexName': 'Please enter the index name',
    'sureRebuildThisIndex': 'Are you sure you want to rebuild this index?',
    'Reindexing': 'Reindexing',
    'sureRegisterThisIndex': 'Are you sure you want to register this index?',
    'RegisterTheIndex': 'Register the index',
    'InstanceManipulation': 'Instance manipulation',
    'viewAndDeleteInstance': 'This function is used to view and delete instance operations. Below is the list of instances',
    'clusterNodesNum': 'Note that the number of cluster nodes is ',
    'instancesIsAbnormal': 'The number of instances is abnormal, please check the instance information!',
    'sureDeleteTheInstance': 'Are you sure you want to delete the instance?',
    'buildDataTaskHasStarted': 'Wait, the build data task has started and is running asynchronously',
    'FailExecuteBuildData': 'Failed to execute build data',
    'vulnerabilityUpdateTaskStarted': 'Please wait, the vulnerability data update task has been started and is running asynchronously',
    'updateFailExecute': 'The update failed to execute',
    'currentInstanceNotAllowDeleted': 'The current instance cannot be deleted',
    'DeleteInstanceSuccessful': 'Delete instance successfully',
    'reindexingWasSuccessful': 'The reindexing was successful',
    'FailReindex': 'Failed to reindex',
    'RegisterIndexSuccessful': 'Register index successfully',
    'FailRegisterTheIndex': 'Failed to register the index',
    'updateStatusSuccessful': 'The status update was successful',
    // DataMigration.vue
    'ProductsNum': 'Total number of products',
    'LastMigration': 'Last migration',
    'OperationUser': 'Operation user',
    'OperationTime': 'Operation time',
    'MigratedProducts': 'Migrated products',
    'MigrationProgress': 'Migration progress',
    'TransitionState': 'Transition state',
    'BatchSize': 'Batch size',
    'enterTheBatchNumber': 'Please enter the batch number',
    'surePerformDataMigration': 'Are you sure you want to perform a data migration?',
    'dataMigrationTaskStart': 'Please wait, the data migration task has been started and is executing asynchronously',
    'FailPerformMigration': 'Failed to perform the migration',
    // ArtifactsBackUp.vue
    'EnterStorageSpace': 'Enter the storage space query',
    'BackupRepository': 'Backup repository',
    'selectTheBackupRepository': 'Please select the backup repository',
    'BackupDirectory': 'Backup directory',
    'selectTheBackupDirectory': 'Please select the backup directory',
    'sureEnableBackup': 'Are you sure you want to enable backup?',
    'EnterTheRepositoryQuery': 'Enter the repository query',
    'backupPolicyIsSetSuccessful': 'The backup policy is set successfully',
    'FailedToSetBackupPolicy': 'Failed to set backup policy',
    'DeleteSuccess': 'Delete successfully',
    // Webhook/index.vue
    'AccessToken': 'Access token',
    'TriggerEvent': 'Trigger event',
    'enterTheURL': 'Please enter the URL',
    'enterTheAccessToken': 'Please enter the access token',
    'Record': ' record',
    'RecordDetails': ' record details',
    'On': 'Completed in under ',
    'CompletedSeconds': ' seconds',
    'sendingWebhookError': 'An internal error occurred while sending this webhook.',
    'Response': 'Response',
    'Request': 'Request',
    'enterAValidURL': 'Please enter a valid URL',
    'ArtifactUpload': 'Artifact upload',
    'uploadingArtifact': 'URL triggered when uploading artifact',
    'ArtifactUpdate': 'Artifact update',
    'uploadingTheSameArtifactAgain': 'URL triggered when uploading the same artifact again',
    'ArtifactDownload': 'Artifact download',
    'downloadingArtifact': 'URL triggered when downloading artifact',
    'ArtifactDeletion': 'Artifact deletion',
    'deletingArtifact': 'URL triggered when deleting artifact',
    'DirectoryDeletion': 'Directory deletion',
    'deletingArtifactDirectory': 'URL triggered when deleting artifact directory',
    'SecurityBlockage': 'Security blockage',
    'downloadIsBlockedBySecurityPolicy': 'URL triggered when download is blocked by security policy',
    'URLLengthLimit': 'URL length should be between 10 and 255 characters',
    'AccessTokenLengthLimit': 'Access token length should be between 1 and 255 characters',
    'selectATriggerEvent': 'Please select a trigger event',
    'RequestTime': 'Request time',
    'WebhookUpdateSuccess': 'Webhook update successful',
    'WebhookAddedSuccess': 'Webhook added successfully',
    'WebhookDeletedSuccess': 'Webhook deleted successfully',
    'WebhookRecordDeletedSuccess': 'Webhook record deleted successfully',
    'TestedWebhook': 'Successfully tested ',
    'successTestWebhook': ' webhook',
    'WarehouseName': 'Warehouse name',
    'ProductPath': 'Product path',
    'ManualUpdate': 'Manual update',
    'RegularUpdate': 'Regular update',
    'Timing': 'Timing',
    'SureUpdateTheVulnerabilityDataCron': 'Are you sure to set up scheduled updates for vulnerability data?',
    'CronRules': 'Please enter cron rules',
    'VulnerabilityUpdateCronTask': 'The task of regularly updating vulnerability data has been successfully set up',
    'SureDeleteTheVulnerabilityDataCron': 'Are you sure to delete the scheduled vulnerability update data task?',
    'DeleteRegularUpdate': 'Delete regular Update',
    'BugArtifacts': 'Bug artifacts list',
    'DownloadCount': 'Download count',
    'ScanSettings': 'Scan settings',
    'ArtifactScanTips': 'Full artifact scanning setting, applied to warehouses that have enabled scanning',
    'ArtifactScanNow': 'Are you sure to perform a full scan of the artifact now？',
    'SureUpdateTheArtifactScanCron': 'Are you sure to set a scheduled full scan artifact task?',
    'ArtifactScanCronTask': 'The task of timed full scanning of artifacts has been successfully set',
    'SureDeleteTheArtifactScanCron': 'Are you sure to delete the scheduled full scan artifact task?',
    'ScanImmediately': 'Scan immediately',
    'ManualScanning': 'Manual scanning',
    'ScheduledScan': 'Scheduled scanning',
    'TheLastScan': 'Last scan',
    'ArtifactScanType': 'Type',
}
