export default {
    // Settings.vue
    'GlobalConfiguration': '全局配置',
    'TheWhiteList': '白名单',
    'SureRemovedWhitelist': '确定要从白名单移除吗?',
    'Blacklist': '黑名单',
    'SureRemovedBlacklisted': '确定要从黑名单移除吗?',
    'BeSure': '确定',
    'Cancel': '取消',
    'Seriously': '严重',
    'MediumRisk': '中危',
    'HighRisk': '高危',
    'LowRisk': '低危',
    'selectUser': '请选择用户',
    'EnterEmail': '请输入邮箱',
    'VulnerabilityLevel': '漏洞等级',
    'SureDelete': '确定要删除吗?',
    'RemovedWhitelistSuccess': '从白名单移除成功',
    'RemovedBlacklistSuccess': '从黑名单移除成功',
    'AddWhitelistSuccess': '添加到白名单成功',
    'AddBlacklistSuccess': '添加到黑名单成功',
    'OperationSuccessful': '操作成功',
    'GlobalConfig': '全局配置',
    'BasicInfoConfig': '基础信息配置',
    'configuration': '配置',
    'NetworkAgentConfig': '网络代理配置',
    'AdvancedConfig': '高级配置',
    'BasicConfig': '基础配置',
    'ApplicationName': '应用名称',
    'NodeTransmissionRateLimit': '节点传输限速（KB/s）',
    'ProductTransferSliceSize': '制品传输切片大小（MB）',
    'PortNumber': '端口号',
    'Note': '说明:',
    'autoChangeConfigFile': '应用名称修改会自动修改到配置文件',
    'TransferSliceSize': '制品传输切片大小，大制品节点之间传输切片大小',
    'NodeSpeedLimit': '节点传输限速（KB/s），不设置则不限速。传输节点限速未设置，则采用全局限速',
    'useBaseUrl': 'baseurl，如果你使用了反向代理公网等情况下可以使用它',
    'communicationPort': '主服务的后端通信端口',
    'username': '用户名',
    'Password': '密码',
    'Port': '端口',
    'ProtocolType': '协议类型',
    'SetSystemMail': '该配置是用来设置系统邮件',
    'EmailNotification': '程序中某些事件会对相关用户进行邮件通知',
    'NetworkProxy': '网络代理',
    'ProxyUsername': '代理用户名',
    'ProxyPassword': '代理密码',
    'ProxyAddress': '代理地址',
    'Type': '类型',
    'NetworkCommunicationFailure': '该配置是用来针对某些情况下网络无法通信的情况下',
    'accessPublicNetwork': '例如需要配置代理后可以访问公网',
    'AcquisitionDependency': '可以通过代理获取公网仓库的依赖',
    'ForExample': '例如：',
    'TurnOnAll': '开启所有',
    'TurnOn': '开启',
    'OnAllMeans': '开启所有意味着不再有跨域限制',
    'AllowingAnonymousAccess': '允许匿名访问',
    'DisplayValidationFile': '显示校验文件',
    'OpenAllowsAnonymousAccess': '开启允许匿名访问，匿名用户可访问公开库及结构，关闭后匿名用户必须登录方可正常使用。',
    'OpenDisplayVerificationFile': '开启显示校验文件，仓库详情页会展示校验文件，关闭后仓库详情页则不会展示校验文件。',
    'SaveOperation': '保存操作',
    'saveButton': '该保存按钮将会针对以上的修改统一保存',
    'Cancel1': '取 消',
    'Save1': '保 存',
    'Save': '保存',
    'SecurityPolicy': '安全策略',
    'NotificationSet': '通知设置',
    'NotifyVulnerabilityLevel': '通知漏洞等级',
    'SelectVulnerabilityLevel': '请选择漏洞等级',
    'NotificationPolicy': '通知策略',
    'NotifyPlatformAdministrator': '通知平台管理员',
    'NotifyStorageAdministrator': '通知存储空间管理员',
    'DesignatedUser': '指定用户',
    'SpecifyEmailAddress': '指定邮箱',
    'BlockingSet': '阻断设置',
    'SelectBlockingMode': '请选择阻断方式',
    'FullBlock': '全量阻断',
    'autoFilterBlacklist': '（tips： 此种阻断方式会自动过滤黑名单）',
    'FilteringWhitelist': '过滤白名单',
    'BlacklistBlocking': '黑名单阻断',
    'autoFilterWhitelist': '（tips：此种阻断方式会自动过滤白名单）',
    'PackageNameBlocking': '包名阻断',
    'InterceptByPacketName': '（tips：此种阻断方式会按照包名拦截）',
    'Add': '新增',
    'ConnectionConfig': '连接配置',
    'UserMapping': '用户映射',
    'RoleMapping': '角色映射',
    'LDAPConnection': '该部分配置用于和LDAP建立连接',
    'BindingDN': '绑定DN',
    'BindingPasswords': '绑定密码',
    'EnableLDAPServer': '是否开启LDAP服务',
    'ShutDown': '关闭',
    'NextStep': '下一步',
    'UsersSearchForObjects': '用户搜索对象',
    'UserFilterCriteria': '用户过滤条件',
    'userDNList': '在验证查找用户时将使用以下用户DN列表',
    'Is64Encrypted': '用户密码是否是Base64加密？',
    'Yes': '是',
    'No': '否',
    'Back': '上一步',
    'RoleMatching': '角色匹配',
    'Match': '匹配',
    'FilteringCriteria': '过滤条件',
    'RoleAttributes': '角色属性',
    'Role': '角色',
    'EnterLDAPRole': '输入LDAP的角色',
    'Done': '完成',
    'NodeLicenseConfig': '节点许可配置',
    'LicenseInfo': '许可信息',
    'userChoose': '尊敬的用户,很荣幸您选择使用',
    'InWhatFollows': '在接下来',
    'provideYou': '将会为您提供统一软件包管理。',
    'ITDigital': 'IT数字化转型道路长远，',
    'IsWithYou': '与您随行！',
    'serviceHotline': '如果觉得符合您企业信创发展战略，可选择购买正式版本。我们的服务热线：400-991-5355',
    'serialNumberExpired': '您的序列号已经过期，为了更好的为您提供服务请尽快续期。我们的服务热线：400-991-5355',
    'NotActivated': '未激活',
    'HasExpired': '已过期',
    'Activated': '已激活',
    'MachineCode': '机器码',
    'VersionType': '版本类型',
    'None': '无',
    'ExpirationDate': '有效日期',
    'SerialNumber': '序列号',
    'FunctionalLevel': '功能等级',
    'WhetherItIsActiveOrNot': '是否激活',
    'ActivateSequenceNum': '激活序列号',
    'OfficiallyActivated': '正式激活',
    'TryItOut': '试用',
    'FOLIBSerialNumber': '你可以复制左侧机器码来获取FOLIB的序列号，并将序列号填入下方。正式激活请确保激活过程可以联网，如果需要开通网络策略，请将"license.folib.com"设置为白名单，如果想通过离线方式激活请联系我司技术服务',
    'machineCode': '请粘贴本机器码的序列号',
    'MetadataConfig': '元数据配置',
    'Display': '展示',
    'NotToShow': '不展示',
    'NodeDistributeConfig': '节点分发配置',
    'InternalNodes': '内部节点',
    'Online': '在线',
    'Offline': '离线',
    'Auto': '自动',
    'Manual': '手动',
    'NoSpeedLimit': '不限速',
    'ExternalNodes': '外部节点',
    'AddMetadata': '新增元数据',
    'ModifyingMetadata': '修改元数据',
    'MetadataKEY': '元数据KEY',
    'EnterMetadataKEY': '请输入元数据KEY',
    'MetadataTypes': '元数据类型',
    'SelectMetadataType': '请选择元数据类型',
    'WhetherToDisplay': '是否展示',
    'AddWhitelist': '添加白名单',
    'AddBlacklist': '添加黑名单',
    'EnterVulnerabilityNum': '请输入漏洞编号',
    'AddDistributeConfig': '新增分发配置',
    'ModifyDistributeConfig': '修改分发配置',
    'ClusterNodeEnglish': '集群节点英文名',
    'EnterClusterNodeEnglish': '请输入集群节点英文名',
    'ClusterChineseName': '集群中文名',
    'EnterClusterChinese': '请输入集群中文名',
    'ClusterNodeAddress': '集群节点地址',
    'EnterClusterNodeAddress': '请输入集群节点地址',
    'ClusterDescribe': '集群描述',
    'EnterDescribe': '请输入描述',
    'EnterTransmissionLimitOfNode': '请输入节点传输限速（KB/s）',
    'distributeType': '分发类型',
    'SelectDistributeType': '请选择分发类型',
    'LocalCluster': '本集群',
    'SyncData': '同步数据',
    'ClientUniqueIdentifier': '客户端唯一标识',
    'ClientID': '客户端ID',
    'EnterClientID': '请输入客户端ID',
    'Edit': '编辑',
    'ClientKey': '客户端密钥',
    'EnterClientKey': '请输入客户端密钥',
    'ClientName': '客户端名称',
    'CustomClientName': '自定义客户端名称，用于区别多个单点登录配置',
    'EnterClientName': '请输入客户端名称',
    'LoginAddress': '登录地址',
    'ThirdPartySystemProvideLogin': '单点登录系统的登录地址，由第三方登录系统提供',
    'EnterLoginPageAddress': '请输入登录页面地址',
    'LoginRedirectAddress': '登录重定向地址',
    'RedirectAddressAfterLogin': '登录后的重定向地址',
    'EnterRedirectAddress': '请输入登录后的重定向地址',
    'LogoutAddress': '登出地址',
    'ClearSession': '单点登录系统的登出地址，由第三方登录系统提供，用于清除服务端的会话',
    'EnterYourLogoutAddress': '请输入登出地址',
    'LogOutRedirectURL': '登出重定向URL',
    'RedirectURLAfterLogin': '登出后的重定向地址',
    'EnterRedirectURL': '请输入登出后的重定向URL',
    'Address': '地址',
    'GetInterfaceAddress': '获取AccessToken的接口地址',
    'EnterAccessTokenAddress': '请输入AccessToken地址',
    'UserInfoAddress': '用户信息地址',
    'EnterUserInfoAddress': '获取用户信息的接口地址',
    'EnterInformationAddress': '请输入用户信息地址',
    'UserNameField': '用户名称字段',
    'setUserNameField': '设置该字段后，将使用该字段所对应的值作为系统的用户名',
    'EnterUserNameField': '请输入用户名称字段',
    'ConfigInstructions': '配置说明',
    'SSOConfigCustomDescribeInfo': '该单点登录配置自定义的描述信息',
    'EnterConfigInstructions': '请输入配置说明',
    'Node': '节点',
    'describe': '描述',
    'distributeMethod': '分发方式',
    'OnlineStatus': '在线状态',
    'AddMethod': '添加方式',
    'Operation': '操作',
    'enterNodeUrl': '请输入节点url',
    'RedirectAddress': '重定向地址',
    'SavedSuccess': '保存成功',
    'CopySuccess': '复制成功',
    'SuccessfulActivation': '激活成功',
    'ActivationFailed': '激活失败',
    'UnableToActivate': '无法激活',
    'NoSequenceNumberWasEntered': '没有输入序列号',
    'BlockingSetSavedSuccess': '阻断设置保存成功',
    'NotificationSetSavedSuccess': '通知设置保存成功',
    'ClusterDistributeConfigExists': '集群分发配置已存在',
    'distributeConfigSuccessful': '分发配置成功',
    'MetadataKEYyExists': '元数据KEY已存在',
    'metadataConfigSuccess': '元数据配置成功',
    'DeleteDistributeConfigSuccess': '删除分发配置成功',
    'DeletingMetadataWasSuccessful': '删除元数据成功',
    'EditClient': '编辑客户端',
    'Tips': '提示',
    'DeleteClientSuccess': '删除客户端成功',
    'AddNewClient': '新增客户端',
    'AddedClientSuccess': '新增客户端成功',
    'UpdateClientSuccess': '更新客户端成功',
    'ClusterEnNameLengthLimit': '长度在 1 到 60 个字符',
    'CheckKey': '长度在 1 到 30 个字符',
    'CheckDispatchType': '请选择分发方式',
    'Number': '数字',
    'String': '字符串',
    'Text': '文本',
    // Monitor.vue
    'BaseMonitoring': '基础监控',
    'SystemLog': '系统日志',
    'dataQuery': '数据查询',
    'clusterInformation': '集群信息',
    // QueryData.vue
    'QueryLog': '查询日志',
    'Query': '查询',
    'SeeOfficialDocument': '查询语言请看官方文档',
    '8182Port': '默认查询内置的Gremlin数据库server端口为8182',
    'TopologicalResult': '拓扑结果',
    // BaseData.vue
    'handleCase': '句柄情况',
    'maximumSystemHandle': '系统最大句柄为',
    'StorageSize': '存储大小',
    'MaximumSystemSpace': '系统最大空间',
    'availableJVM': '指JVM可用内存大小',
    'AvailableMemory': '可用内存',
    'NumberOfThreads': '线程数量',
    'currentProgramNum': '当前程序的线程数量',
    'SystemLoad': '系统负载',
    'CurrentSystemLoadEstimate': '当前系统的负载评估值',
    'TimeConsuming': '耗时',
    'ExecutionTime': '执行时间',
    'SystemCPU': '系统CPU',
    'FolibProcessCPU': 'Folib进程CPU',
    'MemoryUsage': '内存使用情况',
    'CPUUsage': 'CPU使用情况',
    'MaxAvailableMemory': '最大可用内存',
    'FolibCurrentlyUsesMemory': 'Folib当前使用内存',
    'InTheThreadCase': '线程情况',
    'NumberOfActiveThreads': '活动线程数',
    // SystemLog.vue
    'LogList': '日志列表',
    'OnlineLogView': '在线日志查看',
    'synchronization': '同步',
    // ClusterInfo.vue
    'DataRepair': '数据修复',
    'NodeInformation': '节点信息',
    'DangerousOperation': '危险操作！！确定要移除此节点吗？',
    'BasicInformation': '基本信息',
    'Status': '状态',
    'UsingPorts': '使用端口',
    'TotalNumberOfDataTables': '数据表总数',
    'DataOwnership': '数据所有权',
    'EstimatedDataSize': '预估数据量',
    'RemovingNodes': '正在移除节点',
    'refreshThePageToCheck': ',请稍候刷新页面查看',
    'SuccessRemovedNode': '成功移除节点:',
    'clusterDataIsRepaired': '集群数据修复完成',
    // AuditLog.vue
    'auditLog':'审计日志',
    'auditModule':'审计模块',
    'auditEvent':'审计事件',
    'eventTime':'事件时间',
    'startTime':'起始时间',
    'endTime':'结束时间',
    'detail':'操作详情',
    'result':'操作结果',
    'operator':'操作人',
    'createTime':'操作时间',
    'target':'操作目标',
    'auditConfig':'审计配置',
    'artifactRepository':'制品仓库',
    'userManagement':'用户管理',
    'systemSetting':'系统设置',
    'advanceSetting':'高级运维',
    // Advanced.vue
    'StorageSpace': '存储空间',
    'OwnedWarehouse': '所属仓库',
    'BuildData': '构建数据',
    'BuildAnArtifactIndex': '构建制品索引',
    'migrateArtifactData': '该功能用于从 JFrog Artifactory、Sonatype Nexus 迁移制品数据',
    'InMostRecentBuild': '最近一次构建',
    'User': '用户',
    'Time': '时间',
    'Parameters': '参数',
    'Error': '错误',
    'sureChangeState': '确定要更改状态吗?',
    'ManualClosing': '手动结束',
    'In': '中',
    'selectTheStorageSpace': '请选择存储空间',
    'selectYourRepository': '请选择所属仓库',
    'ProductAbsolutePath': '制品相对路径',
    'specifyDirectoryBuildData': '可指定目录进行构建数据，若不填写则为仓库的根目录',
    'DirectoryAbsolutePath': '目录为仓库下的相对路径',
    'enterAbsoluteProductPath': '请输入制品相对路径',
    'SynchronizingMetadata': '同步元数据',
    'sureBuildData': '确定要构建数据吗?',
    'MavenDataMigration': 'Maven数据迁移',
    'ArtifactDataMigration': '制品数据迁移',
    'MavenIndxerSonatypeNexus': '该功能用于从 JFrog Artifactory、Sonatype Nexus 使用MavenIndxer方式迁移制品数据',
    'BugUpdate': '漏洞更新',
    'UpdateVulnerabilityData': '更新漏洞数据',
    'updateDataToLibrary': '该功能用于更新漏洞数据至本地漏洞库',
    'TheLastUpdate': '最近一次更新',
    'SureUpdateTheVulnerabilityData': '确定现在执行更新漏洞数据吗?',
    'Update': '更新',
    'BackupStrategy': '备份策略',
    'setTheBackupPolicy': '该功能用于设置备份策略备份指定存储空间、仓库的制品数据',
    'CachingStrategy': '缓存策略',
    'setSSDCacheAccelerationStrategy': '该功能用于设置制品的SSD缓存加速策略，推荐存储卷采用高性能SSD磁盘',
    'Information': '信息',
    'viewTheSchemaInformation': '该功能用于查看Schema信息',
    'Notice': '注意',
    'checkIndexInformation': '索引未生效，请检查索引信息！',
    'IndexingOperations': '索引操作',
    'usedIndexReconstructionRegistration': '该功能用于索引的重建、注册',
    'IndexName': '索引名称',
    'enterTheIndexName': '请输入索引名称',
    'sureRebuildThisIndex': '确定要重建该索引吗?',
    'Reindexing': '重建索引',
    'sureRegisterThisIndex': '确定要注册该索引吗?',
    'RegisterTheIndex': '注册索引',
    'InstanceManipulation': '实例操作',
    'viewAndDeleteInstance': '该功能用于查看、删除实例操作，下方为实例列表',
    'clusterNodesNum': '注意，集群节点数量为',
    'instancesIsAbnormal': '实例数量存在异常，请检查实例信息！',
    'sureDeleteTheInstance': '确定要删除该实例吗?',
    'buildDataTaskHasStarted': '请稍等，构建数据任务已启动，正在异步执行',
    'FailExecuteBuildData': '执行构建数据失败',
    'vulnerabilityUpdateTaskStarted': '请稍等，漏洞数据更新任务已启动，正在异步执行',
    'updateFailExecute': '执行漏洞更新失败',
    'currentInstanceNotAllowDeleted': '当前实例不允许删除',
    'DeleteInstanceSuccessful': '删除实例成功',
    'reindexingWasSuccessful': '重建索引执行成功',
    'FailReindex': '执行重建索引失败',
    'RegisterIndexSuccessful': '注册索引执行成功',
    'FailRegisterTheIndex': '执行注册索引失败',
    'updateStatusSuccessful': '状态更新成功',
    // DataMigration.vue
    'ProductsNum': '制品总数',
    'LastMigration': '最近一次迁移',
    'OperationUser': '操作用户',
    'OperationTime': '操作时间',
    'MigratedProducts': '迁移制品',
    'MigrationProgress': '迁移进度',
    'TransitionState': '迁移状态',
    'BatchSize': '批处理数量',
    'enterTheBatchNumber': '请输入批处理数量',
    'surePerformDataMigration': '确定要执行数据迁移吗?',
    'dataMigrationTaskStart': '请稍等，数据迁移任务已启动，正在异步执行',
    'FailPerformMigration': '执行数据迁移任务失败',
    // ArtifactsBackUp.vue
    'EnterStorageSpace': '输入存储空间查询',
    'BackupRepository': '备份仓库',
    'selectTheBackupRepository': '请选择备份仓库',
    'BackupDirectory': '备份目录',
    'selectTheBackupDirectory': '请选择备份目录',
    'sureEnableBackup': '确定要开启备份吗？',
    'EnterTheRepositoryQuery': '输入所属仓库查询',
    'backupPolicyIsSetSuccessful': '备份策略设置成功',
    'FailedToSetBackupPolicy': '备份策略设置失败',
    'DeleteSuccess': '删除成功',
    // Webhook/index.vue
    'AccessToken': '访问令牌',
    'TriggerEvent': '触发事件',
    'enterTheURL': '请输入URL',
    'enterTheAccessToken': '请输入访问令牌',
    'Record': '记录',
    'RecordDetails': '记录详情',
    'On': '在',
    'CompletedSeconds': '秒内完成',
    'sendingWebhookError': '发送此webhook时发生内部错误。',
    'Response': '响应',
    'Request': '请求',
    'enterAValidURL': '请输入正确的URL',
    'ArtifactUpload': '制品上传',
    'uploadingArtifact': '上传制品时触发 URL',
    'ArtifactUpdate': '制品更新',
    'uploadingTheSameArtifactAgain': '再次上传同一制品时触发 URL',
    'ArtifactDownload': '制品下载',
    'downloadingArtifact': '下载制品时触发 URL',
    'ArtifactDeletion': '制品删除',
    'deletingArtifact': '删除制品时触发 URL',
    'DirectoryDeletion': '目录删除',
    'deletingArtifactDirectory': '删除制品目录时触发 URL',
    'SecurityBlockage': '安全阻断',
    'downloadIsBlockedBySecurityPolicy': '下载被安全策略阻断时触发 URL',
    'URLLengthLimit': 'url长度在10到255个字符',
    'AccessTokenLengthLimit': '访问令牌长度在1到255个字符',
    'selectATriggerEvent': '请选择触发事件',
    'RequestTime': '请求时间',
    'WebhookUpdateSuccess': '更新webhook成功',
    'WebhookAddedSuccess': '新增webhook成功',
    'WebhookDeletedSuccess': '删除webhook成功',
    'WebhookRecordDeletedSuccess': '删除webhook记录成功',
    'TestedWebhook': '测试',
    'successTestWebhook': 'webhook成功',
    'WarehouseName': '仓库名称',
    'ProductPath': '制品路径',
    'ManualUpdate': '手动更新',
    'RegularUpdate': '定时更新',
    'Timing': '定时',
    'SureUpdateTheVulnerabilityDataCron': '确定设置定时更新漏洞数据吗?',
    'CronRules': '请输入Cron规则',
    'VulnerabilityUpdateCronTask': '定时更新漏洞数据任务设置成功',
    'SureDeleteTheVulnerabilityDataCron': '确定删除定时更新漏洞数据任务吗?',
    'DeleteRegularUpdate': '删除任务',
    'BugArtifacts': '漏洞制品列表',
    'DownloadCount': '下载次数',
    'ScanSettings': '扫描设置',
    'ArtifactScanTips': '全量制品扫描设置，作用于已开启扫描的仓库',
    'ArtifactScanNow': '确定现在执行全量扫描制品吗？',
    'SureUpdateTheArtifactScanCron': '确定设置定时全量扫描制品任务吗?',
    'ArtifactScanCronTask': '定时全量扫描制品任务设置成功',
    'SureDeleteTheArtifactScanCron': '确定删除定时全量扫描制品任务吗?',
    'ScanImmediately': '立即扫描',
    'ManualScanning': '手动执行',
    'ScheduledScan': '定时扫描',
    'TheLastScan': '最近一次扫描',
    'ArtifactScanType': '类型',
}
