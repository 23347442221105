export default {
    // index.vue
    'GotoBrowsePage': 'Click to enter the browsing page',
    'BrowseAddress': 'Browse address',
    'WarehouseBrowseAddress': 'Warehouse browsing address, click to enter the browsing page',
    'UseAddress': 'Use address',
    'WarehouseUsageAddress': 'Warehouse use address, specific use method, please see the right side of the page to use help.',
    'Upload': 'Upload',
    'BatchUpload': 'Batch upload',
    'UseHelp': 'Use help',
    'ScanOn': 'Scan on',
    'ScanOff': 'Scan off',
    'PacketList': 'Packet list',
    'Seriousness': 'Seriousness',
    'HighRisk': 'High risk',
    'MediumRisk': 'Medium risk',
    'LowRisk': 'Low risk',
    'Health': 'Health',
    'ScanFailure': 'Scan failure',
    'More': 'More',
    'Package': 'Package',
    'Document': 'Document',
    'Details': 'Details',
    'Preview': 'Preview',
    'Copy': 'Copy',
    'Move': 'Move',
    'SuerDelete': 'Are you sure you want to delete it?',
    'Delete': 'Delete',
    'Distribute': 'Distribute',
    'DownLoad': 'DownLoad',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'ProductionHistory': 'Production history',
    'TargetWarehouse': 'Target warehouse',
    'InputTargetWarehouse': 'Please enter the target warehouse',
    'SelectTargetWarehouse': 'Please select the target warehouse',
    'TargetDirectory': 'Target directory',
    'InputTargetDirectory': 'Please enter the target directory',
    'Submit': 'Submit',
    'SelectFile': 'Select file',
    'PleaseSelectFile': 'Please select file',
    'UploadMode': 'Upload mode',
    'SelectUploadMode': 'Please select upload method',
    'Product': 'Product',
    'ZipUpload': 'Zip file',
    'ArtifactSingleUploadLimit': 'Only one artifact can be uploaded at a time',
    'NonStandardArtifact': 'Non standard artifact, please manually fill in artifact information',
    'ProductUpload': 'This method supports batch upload of multiple product packages, and cannot exceed 10 files at a time',
    'ZipFileUpload': 'In this mode, you can upload a ZIP file with a maximum size of ',
    'unzippedDirectory': 'The package will be unzipped to this directory in the repository ',
    'NodeType': 'Node type',
    'InternalNode': 'Internal node',
    'ProductWarehouseNode': ' artifact repository nodes',
    'ExternalNode': 'External node',
    'selectAll': 'Select all',
    'NoData': 'No data',
    'NodeTypeSelect': 'Select a node type',
    'OtherTypeNode': 'Other types of artifact library nodes',
    'OwnedStorage': 'Owned storage',
    'OwnedWarehouse': 'Owned warehouse',
    'ProductPath': 'Product path',
    'CreationTime': 'Creation time',
    'LastUsedTime': 'Last used time',
    'DownloadTimes': 'Download times',
    'ProductSize': 'Product size',
    'ServiceShutdown': 'The warehouse is out of service',
    'BrowseNotEnabled': 'The repository directory browsing is not enabled',
    'CopySuccess': 'Copy successful',
    'OperationSuccess': 'Operation successful',
    'CheckProgress': 'Check the upload progress in the upper right corner of the page',
    'EncodingError': 'Encoding error: ',
    'UploadZipPackage': 'Only one compressed package can be uploaded',
    'fileSize': 'The file size cannot exceed ',
    'ZIPFormat': 'You must upload a file in ZIP format!',
    'UploadCount': 'You cannot upload more than 10 files at once',
    'DirectoryFormat': 'The target directory cannot start with /',
    'CopyCustomDirectory': 'Copy to a custom directory',
    'MoveCustomDirectory': 'Move to a custom directory',
    'DistributeCustomDirectory': 'Distribute to a custom directory',
    'Copying': 'Copying, please check later',
    'Moving': 'Moving, please check later',
    'Distributing': 'Distributing, please check laterr',
    'DeletionSuccessful': 'Deletion successful',
    'DeletionFailed': 'Deletion failed',
    'CannotPreview': 'This product cannot be previewed',
    // Data.vue
    'BasicInformation': 'Basic information',
    'OwningSpace': 'Owning space',
    'Name': 'Name',
    'ThePath': 'The path',
    'FileSize': 'File size',
    'ModifyTheTime': 'Modify the time',
    'ImageName': 'Image name',
    'VersionNumber': 'Version number',
    'NumberOfFloors': 'Number of floors',
    'MakeADockerVersion': 'Make a Docker version',
    'MirrorOS': 'Mirror OS',
    'TheInfrastructure': 'The infrastructure',
    'Version': 'Version',
    'Metadata': 'Metadata ',
    'MetadataKey': 'MetadataKey',
    'Create': 'New',
    'View': 'View',
    'ConanInformation': 'Conan Information',
    'ConanBagInfo': 'Conan package Information',
    'Configure': 'Configure',
    'PackageName': 'Package name',
    'User': 'User',
    'Channel': 'Channel',
    'Quote': 'Quote',
    'TheAuthor': 'The author',
    'Permission': 'Permission',
    'PackageInformation': 'Package information',
    'NumberOfPackets': 'Number of packets',
    'UseExamples': 'Use examples',
    'Number': 'Number',
    'String': 'String',
    'Text': 'Text',
    'MetadataType': 'Metadata type',
    'MetadataValues': 'Metadata values',
    'Operations': 'Operations',
    'DeletedProductSuccess': 'Deleted product metadata successfully',
    'BomInformation': 'Bom information',
    // UseDoc
    'Instructions': 'Instructions for use',
    'GlobalConfiguration': ' global configuration',
    'Configuration': ' configuration',
    'SourceConfiguration': ' Source configuration',
    'copyConfiguration': ' You need to copy the following configuration into the /conf/settings.xml of your maven',
    'POMConfiguration': ' Usually need to specify the upload configuration in pom.xml, and commonly used maven repository use, specific pom configuration can see: ',
    'warehouseType': 'Note: This warehouse type is: ',
    'ProxyLibrary': 'Proxy library',
    'CombinatorialLibrary': 'Combinatorial library',
    'LocalLibrary': 'Local library',
    'NotUpload': 'Upload is not supported',
    'SupportUpload': 'Support upload',
    'CommandOperation': 'Command operation',
    'UsuallyCommand': 'Usually using the command',
    'UsuallyUse': 'And usually use ',
    'specificRefer': ', specific refer to: ',
    'ConfigWarehouse': 'Configuration warehouse',
    'gradleCode': 'You need to add the following code to your build.gradle file:',
    'SBTCode': 'You need to edit or create the file ${HOME}/.sbt/repositories and add the following code to the file:',
    'SBTGlobalConfig': 'Edit ${sbt_installdir}/conf/sbtconfig.txt. If you are using IntelliJ IDEA, go to settings->SBT-> jvm parameters and add the following.',
    'UseGlobalConfig': 'Ignore the project custom resolvers and use the global configuration',
    'ivyCode': 'You need to change the ${USER_HOME}/.ivy2/ivysettings.xml file to add the following code:',
    'ConfiguringGlobalConfig': 'Configuring Global configuration',
    'ConfigMirror': 'You can configure npm mirror globally as follows:',
    'OhpmConfigMirror': 'You can configure ohpm\'s mirror globally as follows.',
    'ViewNpm': 'View the current npm configuration',
    'EngineerAllocation': 'Engineering allocation',
    'validCurrent': 'This method is valid only for the current project',
    'npmrcCode': 'You need to create a.npMRc file under the repository and fill it in as follows:',
    'RPMOperation': 'Add a local_test.repo file to /etc/yum.repos.d/ on the Alibaba Cloud image server. Perform the following operations:',
    'folibOperation': 'Add the folib helm bin to the local directory',
    'ChartPackage': 'Upload the Chart package to the Helm repository',
    'HelmPlugin': 'Install the helm-cm-push plugin',
    'HelmCommand': 'Refer to the official website for details',
    'ForReference': 'For reference only, please check the relevant documentation for details',
    'conanConfig': 'Add the folib conan bin to the local action step',
    'ConanOperation': 'Related operations',
    'YarnConfig': "Yarn's mirror can be configured globally as follows:",
    'PypiConfig': 'Write the following.pypirc configuration file:',
    'PackageUpload': 'Package upload',
    'DesignatedRepository': 'This way, the repository is specified when packaging',
    'followingCommand': 'The following command:',
    'DockerVersions': 'For Docker client versions greater than 1.10.0',
    'modifyDaemon': 'You can use this by modifying the daemon configuration file /etc/docker/daemon.json:',
    'MacDocker': 'If you have Docker for Mac installed, you can follow these steps',
    'clickIcon': 'In the taskbar, click the Docker Desktop app icon ->',
    'selectLeft': 'In the left navigation menu, select Docker Engine, ',
    'EditJson': 'Edit the json file in the input field on the right',
    'will': 'will',
    'clickButton': 'Add it to the "insecure registries" array, click the Apply & Restart button, ',
    'waitDocker': 'and wait for Docker to restart',
    'WindowsDocker': 'If you have Docker for Windows installed, you can follow these configuration steps:',
    'RightClickMenu': 'Right-click the menu in the tray icon in the lower right corner of the system Settings，',
    'LeftClickMenu': 'Open the configuration window and select Docker Daemon from the left navigation menu',
    'EditJSON': 'Edit the JSON string in the window and fill in the following address:',
    'ImagePackage': 'Image packaging naming instructions',
    'ImagePackageTips': 'Make sure to check it out, this will determine whether your image package can be uploaded or not:',
    'ImageNamingRules': 'Image naming rules are as follows: repository access url/ storage space/repository name/image name: version number, as follows::',
    'ImageBuildx':'Usage of buildx',
    'ImageBuildxTips':'Using buildx can support displaying multiple architecture products with the same tag',
    'ImageBuildxRules':'The usage of buildx is the same as conventional usage, as follows:',
    'URLRepository': 'Add a default push repository',
    'SeeDocumentation': 'See the documentation for an example',
    'example': 'Here is an example',
    'accessFolib': 'For easy access to folib, append the -Source option to NuGet.exe:',
    'VSConfig': 'See the platform documentation for detailed configuration in Visual Studio',
    'Authentication': ' Authentication',
    'ExecuteCommand': ' Open a command window (windows users) or console (Linux, Mac users) and issue the following command:',
    'Method1': "Method 1: Modifying composer's global configuration file (recommended)",
    'Method2': "Method 2: Modify the composer.json config file for the current project",
    'rootProject': 'Open a command window (windows users) or console (Linux, Mac users), go to the root of your project (the directory where the composer.json file is located), and run the following command:',
    'Unconfigure': 'Unconfigure',
    'GlobalCancel': 'Global cancel',
    'ProjectCancel': 'Project cancellations',
    'useAPI': 'Upload using the API or the page upload buttons',
    'GeneralPurpose': 'General purpose',
    'useCocoapods': 'In order to use cocoapods in Artifactory, you need to install "cocoapods-art". Plugins Install cocoapods-art as follows:',
    'UseStandards': 'repo-art uses the authentication specified in the standards ',
    'SpecifyAuthentication': '',
    'addLibrary': 'To add an Artifactory Specs library:',
    'Deployment': 'Deployment',
    'podDeployment': "To deploy the pod into the Artifactory repository, you need to use Artifactory's REST API or Web UI.",
    'podDeployExample': "For example, to deploy a pod to this repository using the REST API, use the following command: ",
    'PlugIntegration': "Plug-in integration",
    'parsePod': "To parse a Pod from the Artifactory specification repository you added, you have to add the following to the Podfile:",
    'use': "You can then use the installation as usual:",
    'WarehouseAddress': "Warehouse address",
    'WarehouseUseAddress': "Warehouse address",
    'Client': 'client',
    'ConanRepositoryAdd': 'Add Conan Repository to Local',
    'ConanLogin': 'Add access username and password',
    'ConanSearch': 'Search for locally existing packages',
    'ConanCreate': 'Package locally and download dependencies',
    'ConanUpload': 'Upload local package',
    'ConanRemoteList': 'Query added remote repositories',
    'NpmConfig': 'View the current configuration of NPM',
    'IS': 'is',
    'NpmAuth': 'You can also use username and password mode',
    'NpmInstall': 'Installation dependencies',
    'NpmPublish': 'Upload artifacts',
    'SBTIgnore': 'Ignoring custom resolutions for engineering and adopting global configuration',
    'RpmClean': 'Clear YUM cache',
    'RpmRepoList': 'Show all repositories',
    'HelmCMPush': 'Download the help-cm-push installation package for each system',
    'HelmDecompression': 'Copy the installation package to the plugins directory of help and decompression it',
    'HelmUploadCmd': 'Command upload',
    'HelmUploadCmdBin': 'Enter the helm-cm-push plugins plugin bin directory # help env to view the location of the plugins directory',
    'HelmUploadExecute': 'Execute upload',
    'HelmUploadExample': 'For example, upload the/app/fluent 4.5.2. tgz chart package to',
    'HelmUploadParamsExplain': 'Parameter Description: The first parameter is the full path of the cahrt package, and the second parameter is the name of the locally added help repository --username --password can be used for optional authentication',
    'HelmRepoUpdate': 'Update local repository',
    'HelmRepoSearch': 'Search for local MySQL charts',
    'HelmPull': 'Download the latest MySQL locally --version can be specified',
    // addMetadata
    'Modify': 'Modify',
    'Custom': 'Custom',
    'PleaseSelect': 'Please select ',
    'PleaseEnter': 'Please enter ',
    'Type': 'type',
    'value': 'value',
    'EnterSomethingHere': 'Enter something here',
    'KEYLength': 'Between 1 and 30 characters in length',
    'ItExists': 'It already exists',
    'MetadataSuccess': ' product metadata successfully',
    //go
    'GoProxyConfig':'GoProxyConfig',
    'useGo':'To use this proxy, you need to modify the global proxy of Go in the following way.',
    'CacheStatus': 'Cache status',
    'Uncached': 'Uncached',
    'ProductDetails': 'Product details',
    'SearchList': 'Search list',
    'StartDate': 'Start date',
    'EndDate': 'End date',
    'SelectDate': 'Select date',
    'DisplayQuantity': 'Display quantity',
    'Unassigned': 'Unassigned',
    'RiskScore': 'Risk Score',
    'NumberOfComponents': 'Number of components',
    'VulnerabilitiesNum': 'Number of vulnerabilities',
    'ViewDetail': 'View detail',
    "SshKeygen":"Generate public and private keys with the tool ssh-keygen",
    "PrivateKeyPath":"Setting the private key path",
    "SetRepository":"Setting up the warehouse",
    "SetPublishingRepository":"Setting up a Publishing Library",
    "SslCheck":"ssl checksum",
    "OhpmConfig":"View the current ohpm configuration",
    "SetPublishingId":"Setting up Publishing Users",
    "PublishIdTip": "The result encoded with Base64 (username: password) is the yourpublish_id. For example, if the username is example and the password is password, then yourpublish_id is ZXhhbXBsZTpwYXNzd29yZA==",
    "GoGet": "Add Dependency",
    "GoClean": "Clear dependency cache",
    "GoDownload":"Download Dependencies",
    "GoUploadPreparation":"Preparation for uploading go artifacts",
    "GoUploadCreateFile":"Before uploading go artifacts, the following three files need to be prepared",
    "GoFilenameFormat":"File name format",
    "Version":"Version",
    "Filename":"Filename",
    "FileContent":"File content",
    "GoInfoFormat":"The content is artifact information in the following format",
    "GoInfoTime":"Current UTC time",
    "Example":"Example",
    "ExampleVersion":"Version",
    "GoModFormat":"The content of the mod should be consistent with the content of the aritfact go.mod",
    "GoZipFormat":"Zip is a compressed file of the engineering source code, with a directory structure of module value+@ version number in go.mod before compression",
    "GoZipConentTip1":"Taking the above module values and version numbers as an example, the packaging directory should be example.com/example/hello-world@v1.1",
    "GoZipConentTip2":"Put the source code into example.com/example/hello-world@v1.1 compress directory to v1.1.zip",
    "GoUploadCommand":"Execute upload command",
    "GoUploadUseFolib":"Using the folib command-line tool to upload",
    "Login":"Login",
    "Upload":"Upload",
    "ParameterExplanation":"Parameter Explanation",
    "StorageName":"Storage name",
    "RepositoryName":"Repository name",
    "TargetPath":"The storage path of the artifact in the repository, in the format of module value+@ v+file name in go.mod",
    "ArtifactSelf":"artifact file",
    "ArtifactThree":"Upload three files in sequence to the artifact library",
    'LfsInitialization':'Initialization',
    'LfsAddFile':'Adding files to lfs',
    'SetLfs':'Setting up a GitLfs repository',
    'ShowLfs':'View Configuration',
    "LfsClone":"Clone",
    "LfsPull":"Pull",
    "LfsPush":"Push",
    "LfsAddLock":"Locking files",
    "LfsGetLocks":"Viewing Locked Status",
    "LfsUnlock":"Unlocking files",
    "LfsFilename":"filename",
    "HuggingFaceMLConfigure":"Configure",
    "HuggingFaceMLConfigureInfo":"To configure the Hugging Face client to work with FoLib, add your repository using the following command",
    "HuggingFaceMLConfigureInfo2":"For Hugging Face client version 0.19.0 and above, the HF_HUB_ETAG_TIMEOUT parameter allows you to resolve models using pipelines and tokenizer.",
    "HuggingFaceMLConfigureToken":"Then, to authenticate the Hugging Face client against FoLib, run the following command.",
    "HuggingFaceMLUpload":"Upload model",
    "HuggingFaceMLUploadInfo":"To upload a model into an FoLib repository you need to use the huggingface_hub library:",
    "HuggingFaceMLDownload":"Download model",
    "HuggingFaceMLDownloadInfo":"To resolve a model from an FoLib repository you need to use the huggingface client command:",
    "HuggingFaceMLDownloadInfo2":"If you using Hugging Face client version 0.19.0 and above and have enabled the HF_HUB_ETAG_TIMEOUT parameter, you can now resolve models using libraries such as transformers and diffusers, among others.",
    "HuggingFaceMLDownloadInfo3":"Learn more",
    //pub
    'PubConfigMirror': 'You can globally configure the mirror of the pub, as follows:',
    "PubAdd": "Add Dependency",
    "PubCacheClean": "Clear dependency cache",
    "PubGet":"Download Dependencies",
    "PubPublish":"Upload artifacts",
    "PubPublishConfig":"Upload artifacts configuration",
    "PubPublishConfigMirror":"Configure publish_to as the current warehouse in pubspec.yaml",
    "PubPublishTokenConfig":"Add authorization information",
    "GetToken": "Get token",
    "AddToken": "Add a token to the Pub client, if anonymous access is allowed in the repository, then this operation is not required",
    "ExportPub": "In order for the Pub command-line client to use the repository, you need to run the following command to set the environment variables",
    'ImageTag': 'image tag',
    'InputImageTag': 'Please enter the image tag',
    "ImageTagSpecification": "Image Tag Specification: [PATH]/[NAME]:[TAG]",
    'Image': 'Image files',
    'SubsidiaryFiles': 'Subsidiary files',
    "Prompts": "prompts",
    "DirSize": "The size of the file in the directory is ",
    "ConfirmDownload": "Are you sure you want to download it?",
    'ScanTime': 'Scan time',
}
